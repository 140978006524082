<template>
    <div
        class=""
        ref="container" style="width: 100%; height: 100%;"></div>
</template>
<style>
.cursor-pointer canvas {
    cursor: pointer !important;
}
</style>
<script setup>
import {onBeforeUnmount, onMounted, onUnmounted, ref} from "vue";

const container = ref()
const props = defineProps(['file']);
const wave = ref()
let loadingPromise;
onMounted(() => {
    wave.value = new CircularAudioWave(container.value);
    loadingPromise = wave.value.loadAudio(props.file);
});

const play = async () => {
    await loadingPromise;
    wave.value.play();
}

const pause = () => {
    // wave.value.pause();
    wave.value.context.suspend();
}

defineExpose({
    play,
    pause,
    wave
})

onBeforeUnmount(() => {
    wave.value.context.suspend();
})
</script>
